*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after, :before {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :after, :before {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.tanksies-command {
  color: rgb(245 66 185 / var(--tw-text-opacity) );
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.tanksies-command, .tanksies-money {
  --tw-text-opacity: 1;
  font-weight: 700;
}

.tanksies-money {
  color: rgb(50 205 63 / var(--tw-text-opacity) );
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.z-50 {
  z-index: 50;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-4 {
  margin-bottom: 1rem;
}

.mt-4, .my-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-5 {
  height: 1.25rem;
}

.h-\[160px\] {
  height: 160px;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.w-56 {
  width: 14rem;
}

.w-\[320px\] {
  width: 320px;
}

.w-72 {
  width: 18rem;
}

.border-separate {
  border-collapse: separate;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-tanksies-purple {
  --tw-border-opacity: 1;
  border-color: rgb(65 38 153 / var(--tw-border-opacity) );
}

.border-tanksies-dark-purple {
  --tw-border-opacity: 1;
  border-color: rgb(48 28 115 / var(--tw-border-opacity) );
}

.bg-tanksies-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(65 38 153 / var(--tw-bg-opacity) );
}

.bg-tanksies-dark-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(48 28 115 / var(--tw-bg-opacity) );
}

.fill-white {
  fill: #fff;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
}

.pr-2, .px-2 {
  padding-right: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.font-riffic {
  font-family: Riffic, sans-serif;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.italic {
  font-style: italic;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-tanksies-lightblue {
  --tw-text-opacity: 1;
  color: rgb(66 135 245 / var(--tw-text-opacity) );
}

.text-\[\#FF6662\] {
  --tw-text-opacity: 1;
  color: rgb(255 102 98 / var(--tw-text-opacity) );
}

.text-\[\#FDB043\] {
  --tw-text-opacity: 1;
  color: rgb(253 176 67 / var(--tw-text-opacity) );
}

.text-\[\#FCFC96\] {
  --tw-text-opacity: 1;
  color: rgb(252 252 150 / var(--tw-text-opacity) );
}

.text-\[\#9DDF9D\] {
  --tw-text-opacity: 1;
  color: rgb(157 223 157 / var(--tw-text-opacity) );
}

.text-\[\#9DC0CE\] {
  --tw-text-opacity: 1;
  color: rgb(157 192 206 / var(--tw-text-opacity) );
}

.text-\[\#CC99C8\] {
  --tw-text-opacity: 1;
  color: rgb(204 153 200 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.drop-shadow-tanksies-logo {
  --tw-drop-shadow: drop-shadow(2px 2px 0 #676fe9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@font-face {
  font-family: Riffic;
  font-style: normal;
  font-weight: 700;
  src: url("riffic-bold.2c9100d1.woff") format("woff");
}

.hover\:bg-tanksies-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(65 38 153 / var(--tw-bg-opacity) );
}

.hover\:bg-tanksies-magenta:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 66 185 / var(--tw-bg-opacity) );
}

.hover\:fill-black:hover {
  fill: #000;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:tanksies-weapon-table {
    border-spacing: 25px 4px;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=index.d3417494.css.map */
