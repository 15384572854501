@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Riffic';
    font-style: normal;
    font-weight: 700;
    src: url('riffic-bold.woff') format('woff');
}

@layer components {
    .tanksies-command {
        @apply font-mono font-bold text-tanksies-magenta
    }
    .tanksies-money {
        @apply font-bold text-tanksies-green
    }
    .tanksies-weapon-table {
        border-spacing: 25px 4px;
    }
}
